import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Hero from 'components/hero/TwoColumnWithInput.js';
import Features from 'components/features/ThreeColWithSideImage.js';
import MainFeature from 'components/features/TwoColWithButton.js';
import MainFeature2 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton.js';
import FeatureWithSteps from 'components/features/TwoColWithSteps.js';
import Pricing from 'components/pricing/ThreePlans.js';
import Testimonial from 'components/testimonials/TwoColumnWithImageAndRating.js';
import FAQ from 'components/faqs/SingleCol.js';
import GetStarted from 'components/cta/GetStarted';
import Footer from 'components/footers/FiveColumnWithBackground.js';
import heroScreenshotImageSrc from 'images/first.svg';
import macHeroScreenshotImageSrc from 'images/second.svg';
import prototypeIllustrationImageSrc from 'images/prototype-illustration.svg';
import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>How it Works</Subheading>}
        description={
          <>
            Building an MVP (Minimum Viable Product) to its bare bones involves creating a basic version of your product
            with just enough features to test your core idea and gather feedback from early users. The goal is to
            validate your concept quickly
          </>
        }
        heading={
          <>
            <HighlightedText>Simplify</HighlightedText> your MVP building process.
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        description={
          <>
            Wave goodbye to unpredictable freelancers and costly agencies. Enjoy lightning-fast MVPs delivered at one
            flat monthly fee – so astonishingly quick, it will leave you in awe!
          </>
        }
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>How it works</Subheading>}
        heading={
          <>
            <HighlightedText>Simplify</HighlightedText> your building process.
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/*<MainFeature2*/}
      {/*  subheading={<Subheading>VALUES</Subheading>}*/}
      {/*  heading={*/}
      {/*    <>*/}
      {/*      We Always Abide by Our{" "}*/}
      {/*      <HighlightedText>Principles.</HighlightedText>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  imageSrc={prototypeIllustrationImageSrc}*/}
      {/*  showDecoratorBlob={false}*/}
      {/*  features={[*/}
      {/*    {*/}
      {/*      Icon: MoneyIcon,*/}
      {/*      title: "Affordable",*/}
      {/*      description:*/}
      {/*        "We promise to offer you the best rate we can - at par with the industry standard.",*/}
      {/*      iconContainerCss: tw`bg-green-300 text-green-800`,*/}
      {/*    },*/}
      {/*    {*/}
      {/*      Icon: BriefcaseIcon,*/}
      {/*      title: "Professionalism",*/}
      {/*      description:*/}
      {/*        "We assure you that our templates are designed and created by professional designers.",*/}
      {/*      iconContainerCss: tw`bg-red-300 text-red-800`,*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Flat Fee<HighlightedText> No Surprises!</HighlightedText>
          </>
        }
        description={
          <>
            Experience seamless scalability with MVPBolt's flat monthly fee. No surprises, no hosting cost, and
            unlimited requests - all-inclusive support for your startup's success.
          </>
        }
        plans={[
          // {
          //   name: "Personal",
          //   price: "$17.99",
          //   duration: "Monthly",
          //   mainFeature: "For Individuals",
          //   features: [
          //     "30 Templates",
          //     "7 Landing Pages",
          //     "12 Internal Pages",
          //     "Basic Assistance",
          //   ],
          // },
          {
            name: 'Standard',
            price: '$4999',
            duration: 'Monthly',
            mainFeature: 'Cancel Anytime',
            features: [
              'One request at a time',
              'Average 48 hour delivery',
              'Unlimited MVPs',
              'Unlimited users',
              'MVP hosting costs',
              'Easy credit-card payments',
            ],
            featured: true,
          },
          // {
          //   name: "Enterprise",
          //   price: "$57.99",
          //   duration: "Monthly",
          //   mainFeature: "For Large Companies",
          //   features: [
          //     "90 Templates",
          //     "27 Landing Pages",
          //     "37 Internal Pages",
          //     "Personal Assistance",
          //   ],
          // },
        ]}
      />
      {/*<Testimonial*/}
      {/*  subheading={<Subheading>Testimonials</Subheading>}*/}
      {/*  heading={*/}
      {/*    <>*/}
      {/*      Our Clients <HighlightedText>Love Us.</HighlightedText>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  testimonials={[*/}
      {/*    {*/}
      {/*      stars: 5,*/}
      {/*      profileImageSrc:*/}
      {/*        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",*/}
      {/*      heading: "Amazing User Experience",*/}
      {/*      quote:*/}
      {/*        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",*/}
      {/*      customerName: "Charlotte Hale",*/}
      {/*      customerTitle: "Director, Delos Inc.",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      stars: 5,*/}
      {/*      profileImageSrc:*/}
      {/*        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",*/}
      {/*      heading: "Love the Developer Experience and Design Principles !",*/}
      {/*      quote:*/}
      {/*        "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",*/}
      {/*      customerName: "Adam Cuppy",*/}
      {/*      customerTitle: "Founder, EventsNYC",*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Why wouldn't I just hire a full-time engineering team?",
            answer:
              "Good question! For starters, the annual cost of 1 full-time senior-level engineer now exceeds $250,000, plus benefits (and good luck finding one who knows what he's doing). Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize.",
          },
          {
            question: 'Is there a limit to how many requests I can have?',
            answer:
              "Once subscribed, you're able to add as many requests to your queue as you'd like, and they will be delivered one by one.",
          },
          {
            question: 'How fast will I receive my revisions?',
            answer: 'On average, most requests are completed in just two days or less.',
          },
          {
            question: 'What tech stack do you develop in?',
            answer: 'Most MVPs at MVPBolt are developed using MongoDB, Express, React, and Node.js (MERN).',
          },
          {
            question: "What if I don't like the prototype?",
            answer: "No worries! We'll continue to deliver MVP revisions until you're 100% satisfied.",
          },
          {
            question: 'Are there any hidden fees or extra charges?',
            answer: 'No, MVPBolt offers transparent pricing, and there are no hidden fees or unexpected charges.',
          },
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
